import React, { useState } from 'react';
import './Login.css';
import { LoginPageStyle } from '../../PageStyle/pageStyleVariable';
import GoogleLogo from '../../asset/google-icon-2048x2048-czn3g8x8.png';
import { BaseURL } from '../Masters/masterPagefunctions';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SignInComponents() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

   

    const handleSignMethod = async (e) => {
        e.preventDefault(); // Prevents form default submission
        try {
            const response = await axios.post(`https://masterhub.${BaseURL}/api/v1/dblist`, { email });
            if (response?.data?.data?.length !== 1) {
                navigate('/company', { replace: true })
            }
            else {
                const orgid = response?.data?.data?.[0]?.orgid
                console.log("orgid", orgid);
                sessionStorage.setItem('orgid', orgid);
                navigate('/login', { replace: true })
            }

            const { data } = response.data
            sessionStorage.setItem('email', email);
            sessionStorage.setItem('user-data', JSON.stringify(data));
            console.log("sign method:", data);

        } catch (error) {
            console.error("Error in sign method:", error);
            setError(error?.response?.data?.message);
        }
    };

    return (
        <div className="h-screen">
            <style>
                {`
                    .buttonColor{
                     background-color: #372f93;
                     color:white;
                     padding:0px 20px 0px 20px;
                    }

                    .signInText{
                    color:#372f93;
                    font-size:28.9pt;
                    }

                    /* Modal Styles */
                    .modal-overlay {
                        background-color: rgba(0, 0, 0, 0.5);
                        transition: opacity 0.3s ease-in-out;
                    }

                    .modal-content {
                        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                    }
                `}
            </style>
            <div className="flex h-screen">
                <div className="w-6/12 signBgImage h-screen sm:hidden">
                    {/* Background image section */}
                </div>
                <div className="xl:w-6/12 lg:w-6/12 sm:w-full flex justify-center items-center">
                    <div>
                        <div>
                            <h1 className="signformate">Welcome</h1>
                            <h1 className="signformate">DanaBooks!</h1>
                            <h6 className="signInText">Sign In</h6>
                        </div>
                        <div className="mt-2">
                            <form onSubmit={handleSignMethod}>
                                <div>
                                    <input
                                        className="w-full h-[29px] signinputBorder"
                                        type="text"
                                        placeholder="Email Address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        
                                        //onFocus={handleEmailInteraction}
                                    />
                                </div>
                                <div className="my-2 flex justify-center items-center">
                                    <button type="submit" className="buttonColor w-full">
                                        Continue
                                    </button>
                                </div>
                            </form>
                            <div className="flex justify-center items-center">
                                <hr className="w-full" />
                                <label htmlFor="" className="mx-2">OR</label>
                                <hr className="w-full" />
                            </div>
                            <div>
                                {error}
                            </div>
                            <div className="flex justify-center items-center">
                                <button className="flex justify-center items-center my-3 px-5 py-1 signShadow">
                                    <img
                                        src={GoogleLogo}
                                        className="mr-2"
                                        alt="Google logo"
                                        width={LoginPageStyle.googleWidth}
                                        height={LoginPageStyle.googleHeight}
                                    />
                                    Continue with Google
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    );
}

export default SignInComponents;