import React, { useEffect, useState } from 'react';
import './master.css';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import { useDispatch, useSelector } from 'react-redux';
import { selectFliterFunction } from '../../Redux/Reducer/filterSlice';
import { darkTheme, dayTheme } from '../../PageStyle/colorsdarkWhite';
// const amountfield= [ 

//     {
//         id:2,
//         name:"txn_amount",
//         label:" Amount",
//         taxable:false,
//         taxrate:null,
//         type:"number",
//         sign:null
        

//     },
//     {
//         id:4,
//         name:"taxable_discount",
//         label:"  Discount",
//         taxable:true,
//         taxrate:10,
//         type:"number",
//         sign:"-1"
        

//     },
   
    
//     {
//         id:14,
//         name:"new_taxable_discount",
//         label:"freight",
//         taxable:true,
//         taxrate:10,
//         type:"number",
//         sign:"+1"
        
     


//     },
//      {
//         id:2,
//         name:"calculatetax",
//         label:"Tax Amount",
//         taxable:false,
//         taxrate:null,
//         type:"number",
//         sign:null
        

//     },
   
//     {
//         id:5,
//         name:"non_tax_discount",
//         label:"Non taxable Discount",
//         taxable:false,
//         taxrate:null,
//         type:"number",
//         sign:"-1"

//     },
//     {
//         id:6,
//         name:"non_tax_discount2",
//         label:"Non taxable Discount2",
//         taxable:false,
//         taxrate:null,
//         type:"number",
//         sign:"-1"

//     },
   
//     {
//         id:8,
//         name:"netamount",
//         label:"Net Amount",
//         taxable:false,
//         taxrate:null,
//         type:"number",
//         sign:null

//     },

   
   
  
//     // {
//     //     id:8,
//     //     name:"net_amount",
//     //     label:"Net Amount",
//     //     taxable:false,
//     //     taxrate:null,
      
      
      
//     // }
// ]


function MasterAccount({ accountList, searchValue, setSearchValue,getAccDataUpdate,newRows,formDetails}) {
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const dispatch = useDispatch();

    // Check if accountList is updating correctly

    console.log("accounts",accountList);

//     const initialFormValues = amountfield.reduce((acc, field) => {
//         acc[field.name] = "";
//         return acc;
//     }, {});
//     const [formValues, setFormValues] = useState(initialFormValues);
//     const [amount,setAmount]=useState(amountfield)
//     const taxableFields = amountfield
//   .filter(field => field.taxable)
//   .map(({ name, sign }) => ({ name, sign }));

//   const nontaxableFields = amountfield
//   .filter(field=> !field.taxable) 
//   .map(({name,sign}) => ({name,sign}))

//   const taxvalue=10

//   const totalsum = {
//     txn_amount: newRows?.reduce((sum, item) => sum + item.txn_amount, 0),
//     tax:taxvalue
    
// };

// const taxresult ={}
// const taxableArray= taxableFields.map(field=> {
//     const taxableValue = formValues[field.name]||0;
//     if(taxableValue){
//         const addSign = taxableValue*field.sign
//         taxresult[field.name] = addSign
//     }
   
// })
// const taxamount = {...totalsum,...taxresult}
// console.log("taxamount ",taxamount )
// const {txn_amount,tax,...rest} = taxamount
// const otherfieldSum = Object.values(rest).reduce((sum,value)=> sum+value,0)
// const calculatetax=(txn_amount+otherfieldSum)*tax/100
// const netamount= txn_amount+calculatetax

// const newtaxamount = {
//   txn_amount,
//   calculatetax,
//   netamount,
// };
// const nontaxresult = {};

// // Iterate over each item in the nonTaxable array
// nontaxableFields.forEach(field => {
//   //   const value = formValues[item.name]||0;
//     const nontaxableValue = formValues[field.name]||0;
//     console.log(" nontaxableValue values", nontaxableValue)
//     if(nontaxableValue){
//         const addSign = nontaxableValue*field.sign
//         nontaxresult[field.name] = addSign
//     }
 
    
//     // Apply the sign if it exists, otherwise default to 0 if the value is empty
//   //   nontaxresult [item.name] = value ? value* item.sign :0
// });

// const finalresult= {...newtaxamount}
// const output= {...nontaxresult} 
// const totalDiscount = Object.values(output).reduce((sum, value) => sum + value, 0);
// console.log("totalDiscount",totalDiscount)

// const last = {
//     ...finalresult,
//     netamount: finalresult.netamount + totalDiscount, // Adjust netamount by adding the total discount
// };
// useEffect(()=> {
//     const initialFormValues = amountfield.reduce((acc, field) => {
//         acc[field.name] = "";
//         return acc;
//     }, {});
//  setFormValues(initialFormValues)
// },[formDetails])
    // Filter the account list based on the search query under active values
    // useEffect(() => {
    //     if (Array.isArray(accountList?.accSeleBox)) {
    //         const filtered = accountList?.accSeleBox?.map((account) => ({
    //             ...account,
    //             active: Array.isArray(account.active)
    //                 ? account.active.filter((item) => {
    //                     if (item?.name) {
    //                         return item.name.toLowerCase().includes(searchValue?.toLowerCase());
    //                     } else if (item?.label) {
    //                         return item.label.toLowerCase().includes(searchValue?.toLowerCase());
    //                     }
    //                     return false; // If neither name nor label is present
    //                 })
    //                 : [], // If active is not an array, set it as an empty array
    //         })).filter((account) => account.active.length > 0); // Only keep accounts with matching active items
    
    //         setFilteredAccounts(filtered);
    //     }
    // }, [accountList, searchValue]);



    useEffect(() => {
        if (Array.isArray(accountList?.accSeleBox)) {
            const filtered = accountList.accSeleBox.map((account) => ({
                ...account,
                active: Array.isArray(account.active)
                    ? account.active.filter((item) => {
                        // Check if item has accSelectFileds and compare apilabelkey to searchValue
                        const labelKey = item?.[accountList?.accSelectFileds?.apilabelkey];
                        return labelKey?.toLowerCase().includes(searchValue?.toLowerCase());
                    })
                    : [], // If active is not an array, set it as an empty array
            })).filter((account) => account.active.length > 0); // Only keep accounts with matching active items
    
            setFilteredAccounts(filtered);
        }
    }, [accountList, searchValue]);
    
    


    useEffect(() => {
        console.log("Updated accountList:", filteredAccounts);

    }, [accountList]);

    // const handleSelectAccountButton = (item,accountList) => {
    //     dispatch(selectFliterFunction({ filtername: item?.[accountList?.accSelectFileds?.apilabelkey], filterId: item?.[accountList?.accSelectFileds?.apidatakey]}));
    //     setSearchValue(''); // Optionally reset the search value after selection
        
    // };


    const handleSelectAccountButton = (item, accountList) => {
        // Dispatch the filter function with the selected item's label and data key
        dispatch(selectFliterFunction({
            filtername: item?.[accountList?.accSelectFileds?.apilabelkey], 
            filterId: item?.[accountList?.accSelectFileds?.apidatakey]
        }));
    
        // Optionally reset the search value after selection
        setSearchValue('');
    
        // Clone the object to avoid direct mutation
        const updatedAccData = { ...getAccDataUpdate };
        
        // Update the specific field in the cloned data object
        if (updatedAccData?.data?.[0]) {
            updatedAccData.data[0][accountList?.accSelectFileds?.name] = item?.[accountList?.accSelectFileds?.apidatakey];
        }
    
        // If necessary, update the state or perform any additional operations with the updated data
        // setGetAccDataUpdate(updatedAccData);  // Uncomment if you need to update state
    };

    // const handleChange = (name, value,index) => {
    //     const updatedValues = { ...formValues, [name]: value };
        
    //     setFormValues(updatedValues);
    //     console.log("asss",formValues)
    //     // calculateAmounts(updatedValues);
    //   };
    

    const selectFilterName = useSelector((state) => state.Filternames);
    const darkModeState = useSelector((state) => state.darkMode);

    console.log("selectFilterName.filterId",selectFilterName.filterId)


    return (
        <div className='AccountMenuBgColor w-5/12 sm:hidden sm:h-[84%] lg:h-[84%] xl:h-[84%] overflow-y-auto mt-[95px] shadow'>
            <style>
                {`
                    .accountButtonStyle {
                        background: white;
                        width: 45px;
                        height: 45px;
                        box-shadow: inset 6px 1px 4px 2px #bfbbbb;
                    }

                    .selectedAccoundButton {
                        background: ${darkModeState.checkvalue ? dayTheme.DmenuButnColor : darkTheme.DmenuButnColor};
                        width: 45px;
                        height: 45px;
                        color: white;
                        box-shadow: inset 6px 1px 4px 2px #7e7e7e;
                    }
                `}
            </style>
            <div className='flex justify-end py-2'>
                <input
                    type="text"
                    className='rounded outline-none pl-3'
                    style={{ fontSize: '8px' }}
                    placeholder="Search accounts"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <button className='ml-3'>
                    <ManageSearchRoundedIcon className='text-white' style={{ fontSize: '25px' }} />
                </button>
            </div>
            <div>
                {filteredAccounts?.map((account) => (
                    <div key={account.id}>
                        <h1 className='ml-2 text-white font-bold'>{account.name}</h1>
                        <div className='grid grid-cols-5'>
                            {account?.active?.map((item) => (
                                <div key={item.id} className='divWidth flex flex-col items-center m-2'>
                                    <button
                                        className={`${selectFilterName.filterId == item?.[accountList?.accSelectFileds?.apidatakey] ? 'selectedAccoundButton' : 'accountButtonStyle'} rounded py-2 font-black`}
                                        style={{ fontSize: '15px' }}
                                        onClick={() => handleSelectAccountButton(item,accountList)}
                                    >
                                        {item?.[accountList?.accSelectFileds?.apilabelkey]?.charAt(0)?.toUpperCase() + item?.[accountList?.accSelectFileds?.apilabelkey]?.substr(1, 1)?.toLowerCase()}
                                    </button>
                                    <label className='text-center text-white font-bold' style={{ fontSize: '10px' }}>
                                        {item?.[accountList?.accSelectFileds?.apilabelkey]?.substr(0, 8)}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            {/* <div style={{backgroundColor:"white",margin:"1rem",borderRadius:"10px",paddingBottom:"14px"}}>
            {amount.map((field,index) => (
                <div key={field.id} style={{display:"flex",justifyContent:"space-between",paddingLeft:"0.5rem",paddingRight:"0.5rem",paddingTop:"0.3rem"}}>
                    <div>
                    <label className='accountnetlabel'>{field.label}</label>
                     </div>
                     <div >
                    <input
                       className='accountnetinput'
                        type={field.type}
                        // value={formValues[field.name]}
                        value={last[field.name] || formValues[field.name]}
                        // onChange={(e) => handleChange(field.name, e.target.value,field)}
                        onChange={(e) => handleChange(field.name, e.target.value)}
                    />
                    </div>
                </div>
            ))}
        </div> */}
        </div>
    );
}

export default MasterAccount;
